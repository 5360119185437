import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { getPhantomWallet, getSlopeWallet, getSolflareWallet, getSolletWallet } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import LayoutContainer from "./layout/layout";
import { Home } from "./pages/home/home";
import { UkrainePage } from "./pages/ukraine/ukraine";
import { BugBountyPage } from "./components/pages/BugBountyPage";
import { Communities } from "./pages/communities/communities";
import { CommunityAdd } from "./pages/communities/community-add";
import { CommunityEdit } from "./pages/communities/community-edit";

require('@solana/wallet-adapter-react-ui/styles.css');

const wallets = [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getSlopeWallet()];

const App = () => (
    <ConnectionProvider endpoint="http://127.0.0.1:8899">
        <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<LayoutContainer />}>
                            <Route path="/" element={<Home />} />
                            <Route path="/communities" element={<Communities />} />
                            <Route path="/communities/add" element={<CommunityAdd />} />
                            <Route path="/communities/:slug/edit" element={<CommunityEdit />} />
                            <Route path="/ukraine" element={<UkrainePage />} />
                            <Route path="/bug-bounty" element={<BugBountyPage />} />
                            <Route path=":referral" element={<Home />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </WalletModalProvider>
        </WalletProvider>
    </ConnectionProvider>
);

export default App;