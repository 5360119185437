import { Modal } from "react-bootstrap";
import { isMobile } from 'react-device-detect';

const URL_1 = "https://magiceden.io/marketplace/okay_bears";
const URL_2 = "https://magiceden.io/marketplace/theorcs";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  // console.log(newWindow);
  if (newWindow) newWindow.opener = null
};

export function JackpotModal({ walletId, styleCss, ...props }) {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')}>
            <h4>The Bi-Monthly Raffle is Back!</h4>
            <img src="https://i.imgur.com/JypS5jN.png" className="img-fluid mb-1"></img>
            <h6 className="text-center mt-2">April 28th - May 12th</h6>
            <hr className="mt-2" />
            <h5 className="text-center mb-4">
              Whale Mode Prize:
              <br />
              Okay Bears NFT<br />
              <img src="https://i.imgur.com/y1zyDxb.png" onClick={()=> openInNewTab(URL_1)} className="img-fluid my-2 mx-2 rounded border cursor-pointer" style={{ maxWidth: '86px' }} />
              <br />
              <small>(75 SOL floor)</small>
            </h5>
            <hr />
            <h5 className="text-center mb-4">
              Normal Prizes (All Flips):
              <br />
              5 x Winner - The Orcs NFT<br />
              <img src="https://i.imgur.com/YfAf9Ma.png" onClick={()=> openInNewTab(URL_2)} className="img-fluid my-2 mx-2 rounded border cursor-pointer" style={{ maxWidth: '86px' }} />
              <br />
              <small>(5 SOL floor ea)</small>
            </h5>
            <hr />
            <u>To Enter</u>: <br />
            Hit a 3 win or lose streak = 1 entry.
            <br />
            Hit a 5 win or lose streak = 3 entries
            <br />
            Hit a 7 win or lose streak = 5 entries.
            <br />
            <br />
            <u>Whale Mode Definition:</u><br />
            Any 1 SOL flip or more is a whale flip. You will need whale streak entries in order to enter in whale jackpot
            <br/><br/>
            <u>How do I check entries?</u><br />
            Come into discord (bottom-right) and type !entries. It will show up under your sol amount in the top right corner and count all entries from April 28th.
          </div>
          <div className="card-footer">
            <button
              className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
              onClick={() => { localStorage.setItem('terms', true); props.onHide() }}>
              OKAY
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}