import { get, patch, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_COLLABS_API_URL ?? 'https://dev-api.degencollabs.com/v1';

export const getCommunity = async (slug: any) => {
  const url = `${BASE_URL}/communities/${slug}`
  const response = await get(url);
  return response?.payload;
};

export const listCommunities = async () => {
  const url = `${BASE_URL}/communities`
  const response = await get(url);
  return response?.payload;
};

export const updateCommunity = async (
  slug: any,
  communityUpdates: any,
  Authorization: any
) => {
  const url = `${BASE_URL}/communities/${slug}`
  const response = await patch(url, { ...communityUpdates }, { Authorization });
  return response?.payload;
};

export const createCommunity = async (community: any, Authorization: any) => {
  const url = `${BASE_URL}/communities`
  const response = await post(url, { ...community }, { Authorization });
  return response?.payload;
};

export const disableCommunity = async (slug: any, Authorization: any) => {
  const url = `${BASE_URL}/communities/${slug}/disable`
  const response = await post(url, { }, { Authorization });
  return response?.payload;
};
