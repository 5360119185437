import React, { useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import { disableCommunity, getCommunity, updateCommunity } from "../../api/communities.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";

export const CommunityEdit = () => {
    const { style } = useContext(StyleThemeContext);
    const { auth, signNonce } = useContext(AuthorizationContext);
    const navigate = useNavigate();

    const params = useParams();
    const [community, setCommunity] = useState<any>(null);

    const [name, setName] = useState<any>(null);
    const [slug, setSlug] = useState<any>(null);
    const [logoUrl, setLogoUrl] = useState<any>(null);
    const [twitterUrl, setTwitterUrl] = useState<any>(null);
    const [discordUrl, setDiscordUrl] = useState<any>(null);
    const [marketplaceUrl, setMarketplaceUrl] = useState<any>(null);
    const [backgroundImage, setBackgroundImage] = useState<any>(null);
    const [backgroundImageDark, setBackgroundImageDark] = useState<any>(null);
    const [backgroundImageMobile, setBackgroundImageMobile] = useState<any>(null);
    const [backgroundImageMobileDark, setBackgroundImageMobileDark] = useState<any>(null);
    const [themes, setThemes] = useState<any>([]);

    useEffect(() => {
        const slug = params?.slug?.toLowerCase();
        setSlug(slug);
        fetchCommunity(slug);
    }, [params]);

    useEffect(() => {
        setName(community?.name);
        setLogoUrl(community?.coinImageUrl);
        setThemes(community?.themes);
        setTwitterUrl(community?.twitterUrl);
        setDiscordUrl(community?.discordUrl);
        setMarketplaceUrl(community?.marketplaceUrl);
        setBackgroundImage(community?.backgroundImage);
        setBackgroundImageDark(community?.backgroundImageDark);
        setBackgroundImageMobile(community?.backgroundImageMobile);
        setBackgroundImageMobileDark(community?.backgroundImageMobileDark);
    }, [community]);

    const fetchCommunity = async (slug: any) => {
        const c = await getCommunity(slug);
        setCommunity(c);
    }

    const onLightThemeChange = (event: any) => {
        const isOn = themes?.filter((t: string) => t == 'light')?.length > 0;
        if (isOn) {
            setThemes(themes?.filter((t: string) => t != 'light'));
        }
        else if (!isOn) {
            setThemes([...themes, 'light']);
        }
    }

    const onDarkThemeChange = (event: any) => {
        const isOn = themes?.filter((t: string) => t == 'dark')?.length > 0;
        if (isOn) {
            setThemes(themes?.filter((t: string) => t != 'dark'));
        }
        else if (!isOn) {
            setThemes(['dark', ...themes]);
        }
    }
    const formSubmit = async (event: any) => {
        event.preventDefault();
        console.log(community);
        console.log(event);

        const body = buildBody();
        if (!Object.keys(body).length) {
            return;
        }

        const response = await updateCommunity(
            slug?.toLowerCase(),
            body,
            auth?.idToken
        )

        console.log(response);
        navigate("../../communities", { replace: true });
    }

    const onDisable = async () => {
        const response = await disableCommunity(
            slug?.toLowerCase(),
            auth?.idToken
        );

        console.log(response);
        navigate("../../communities", { replace: true });
    }

    const buildBody = () => {
        const c = ({
            name: name != community?.name ? name : null,
            logoUrl: logoUrl != community?.logoUrl ? logoUrl : null,
            coinImageUrl: logoUrl != community?.logoUrl ? logoUrl : null,
            profileImageUrl: logoUrl != community?.logoUrl ? logoUrl : null,
            backgroundImage: backgroundImage != community?.backgroundImage ? backgroundImage : null,
            backgroundImageDark: backgroundImageDark != community?.backgroundImageDark ? backgroundImageDark : null,
            backgroundImageMobile: backgroundImageMobile != community?.backgroundImageMobile ? backgroundImageMobile : null,
            backgroundImageMobileDark: backgroundImageMobileDark != community?.backgroundImageMobileDark ? backgroundImageMobileDark : null,
            twitterUrl: twitterUrl != community?.twitterUrl ? twitterUrl : null,
            discordUrl: discordUrl != community?.discordUrl ? discordUrl : null,
            marketplaceUrl: marketplaceUrl != community?.marketplaceUrl ? marketplaceUrl : null,
            themes: themes != community?.themes ? themes : null
        });
        const body = Object.fromEntries(Object.entries(c).filter(([_, v]) => v != null));
        return body;
    };

    return (
        <React.Fragment>
            <div className={style}>
                <div className="text-center body-wrapper h-100vh h-100 main-header">

                    <div className={"text-center d-flex main-header h-100vh-desktop"}>
                        <div className="play step1 text-start mt-md-5 pt-md-5 pt-4">
                            <Link to={`/communities`} className="d-flex align-items-center col-md-3 mb-3 text-decoration-none">
                                <span className="fas fa-chevron-left me-2"></span> GO HOME
                            </Link>

                            {
                                community == null &&
                                <div className="my-5 py-5 ">
                                    <div className="cssload-container py-5">
                                        <div className="cssload-zenith"></div>
                                    </div>
                                </div>
                            }

                            {
                                community != null &&
                                <div>
                                    <div className="py-5 text-center card card-cover h-100 overflow-hidden text-white bg-dark rounded-5" style={{ backgroundImage: `url('${(backgroundImageDark ?? backgroundImage) ?? ''}')` }}>
                                        <img className="d-block mx-auto mb-4 img-fluid" src={logoUrl} alt="" width="72" />
                                        <h2>{name}</h2>
                                        <h6><a href={`https://degencoinflip.com/${slug}`} target="_blank">degencoinflip.com/{slug}</a></h6>
                                    </div>
                                    <div className="row g-5 my-3">
                                        <div className="col-12">
                                            <h4 className="mb-3">REQUIRED</h4>
                                            <form className="needs-validation" onSubmit={formSubmit}>
                                                <div className="row g-3">
                                                    <div className="col-sm-12">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" className="form-control" id="firstName" placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                            Valid first name is required.
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="form-label">Slug</label>
                                                        <div className="input-group has-validation">
                                                            <span className="input-group-text">degencoinflip.com/</span>
                                                            <input type="text" className="form-control" id="username" placeholder="Slug" value={slug} disabled onChange={(e) => setSlug(e.target.value)} />
                                                            <div className="invalid-feedback">
                                                                Your slug is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="form-label">Image</label>
                                                        <input type="text" className="form-control" id="address" placeholder="" value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>
                                                    <hr className="my-4" />
                                                    <h4 className="mb-3">OPTIONAL</h4>
                                                    <div className="col-12">
                                                        <label className="form-label">Twitter</label>
                                                        <input type="text" className="form-control" id="email" placeholder="@degencoinflip" value={twitterUrl} onChange={(e) => setTwitterUrl(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="form-label">Discord</label>
                                                        <input type="text" className="form-control" id="email" placeholder="@degencoinflip" value={discordUrl} onChange={(e) => setDiscordUrl(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="form-label">Marketplace</label>
                                                        <input type="text" className="form-control" id="email" placeholder="@degencoinflip" value={marketplaceUrl} onChange={(e) => setMarketplaceUrl(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="form-label">Background Image</label>
                                                        <input type="text" className="form-control" id="address" placeholder="" value={backgroundImage} onChange={(e) => setBackgroundImage(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="form-label">Dark Background Image</label>
                                                        <input type="text" className="form-control" id="address" placeholder="" value={backgroundImageDark} onChange={(e) => setBackgroundImageDark(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="form-label">Background Image Mobile</label>
                                                        <input type="text" className="form-control" id="address" placeholder="" value={backgroundImageMobile} onChange={(e) => setBackgroundImageMobile(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="form-label">Background Image Mobile Dark</label>
                                                        <input type="text" className="form-control" id="address" placeholder="" value={backgroundImageMobileDark} onChange={(e) => setBackgroundImageMobileDark(e.target.value)} />
                                                        <div className="invalid-feedback">
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr className="my-4" />

                                                <h4 className="mb-3">COLOR THEMES</h4>

                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="save-info" onChange={onDarkThemeChange} checked={themes?.includes("dark")} />
                                                    <label className="form-check-label">DARK THEME?</label>
                                                </div>

                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="same-address" onChange={onLightThemeChange} checked={themes?.includes("light")} />
                                                    <label className="form-check-label">LIGHT THEME?</label>
                                                </div>

                                                <hr className="my-4" />

                                                <button className="btn btn-primary btn-lg" type="submit">SAVE</button>
                                                <button type="button" className="btn btn-danger btn-lg px-4 gap-3 ms-2" onClick={onDisable}>DISABLE</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
