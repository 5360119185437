import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listCommunities } from "../../api/communities.service";

export const CommunitiesPage = () => {
    const wallet = useWallet();
    const navigate = useNavigate();
    const [walletCache, setWalletCache] = useState('');
    const [communities, setCommunities] = useState<any[]>();
    useEffect(() => {
        (async () => {
            if (
                !wallet ||
                !wallet.publicKey ||
                !wallet.connected ||
                wallet.publicKey.toString() == walletCache
            ) {
                return;
            }
            setWalletCache(wallet.publicKey.toString());
            fetchCommunities();
        })();
    }, [wallet]);

    const fetchCommunities = async () => {
        const communities = await listCommunities();
        console.log(communities);
        setCommunities(communities);
    }

    const navigateToAddPartnerPage = () => {
        navigate("./add", { replace: true });
    }


    const navigateToPartnerPage = (slug: any) => {
        navigate(`./${slug}/edit`, { replace: true });
    }

    return (
        <>
            <h1>OUR PARTNERS</h1>
            <div className="container px-4 py-5" id="custom-cards">
            <button type="button" className="btn btn-outline-dark btn-lg px-4 gap-3" onClick={navigateToAddPartnerPage}>ADD PARTNER</button>

                {
                    communities == null &&
                    <div className="my-5 py-5 ">
                        <div className="cssload-container py-5">
                            <div className="cssload-zenith"></div>
                        </div>
                    </div>
                }
                <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
                    {
                        communities?.map(community => {
                            return (
                                <>

                                    <div className="col">
                                        <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 cursor-pointer card-button" onClick={() => navigateToPartnerPage(community.slug)} style={{ backgroundImage: `url('${(community.backgroundImageDark ?? community.backgroundImage) ?? community?.logoUrl}')` }}>
                                            <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                                                <h4 className="py-2 mt-5 mb-4 lh-1 fw-bold bg-dark rounded">{community.name}</h4>
                                                <ul className="d-flex list-unstyled mt-auto">
                                                    <li className="me-auto">
                                                        <img src={community?.coinImageUrl} alt="Bootstrap" width="32" height="32" className="rounded-circle border border-white" />
                                                    </li>
                                                    <li className="d-flex align-items-center me-3">
                                                        <small>/{community.slug}</small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }

                </div>
            </div>
        </>
    )
};