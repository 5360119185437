import React, { useContext } from "react"
import { CommunitiesPage } from "../../components/pages/CommunitiesPage";
import { StyleThemeContext } from "../../contexts/style-theme.context";

export const Communities = () => {
    const { style } = useContext(StyleThemeContext);

    return (
        <React.Fragment>
            <div className={style}>
                <div className="text-center body-wrapper h-100vh h-100 main-header">
                    <CommunitiesPage></CommunitiesPage>
                </div>
            </div>
        </React.Fragment>
    );
};